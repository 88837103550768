import { clsx } from 'clsx';
import { CustomLinkProps } from 'common-nextjs';
import Link from 'next/link';
import React from 'react';
import { qsStringify } from 'utils';
import { ItemState } from '~/typings/services/rails/item';

type Props = CustomLinkProps & {
  itemState?: Omit<ItemState, 'available'>;
  page?:
    | 'feedback'
    | 'favorites'
    | 'bumps'
    | 'followers'
    | 'following'
    | 'about';
  query?: any;
  saleModal?: boolean;
  username: string | undefined;
};

const LockerLink: React.FC<Props> = ({
  children,
  className,
  page,
  itemState,
  saleModal,
  username,
  query: additionalQuery = {},
  ...props
}) => {
  const query: any = additionalQuery;

  if (saleModal) {
    query.sale = 1;
  }

  const stringified = qsStringify(query);

  let as = `/locker/${username}`;
  let href = '/locker/[username]';

  if (page) {
    href += `/${page}`;
    as += `/${page}`;
  }

  if (itemState) {
    href += '/[itemState]';
    as += `/${itemState}`;
  }

  as += stringified;
  href += stringified;

  return (
    <Link
      href={href}
      as={as}
      // rel="nofollow noindex" // Only used if the component is an anchor
      prefetch={false}
      legacyBehavior
    >
      <span {...props} className={clsx(className, 'cursor-pointer')}>
        {children}
      </span>
    </Link>
  );
};

export default LockerLink;
