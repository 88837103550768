import { clsx } from 'clsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import HorizontalDots from '~/components/Icons/HorizontalDots';
import SuperlativeLabelTooltip from '~/components/ItemDetails/SuperlativeLabelTooltip';
import ItemThumbnailOverlay from '~/components/ItemThumbnail/ActionsOverlay/ItemThumbnailOverlay';
import ItemFreeShippingPill from '~/components/ItemThumbnail/ItemFreeShippingPill';
import { ItemLabelPill } from '~/components/ItemThumbnail/ItemLabelPill';
import ItemThumbnailImage from '~/components/ItemThumbnail/ItemThumbnailImage';
import SuperScriptedCurrency from '~/components/ItemThumbnail/SuperScriptedCurrency';
import UsernameAvatarRow from '~/components/ItemThumbnail/UsernameAvatarRow';
import ItemDetailsLink from '~/components/Links/ItemDetailsLink';
import { useSession } from '~/contexts/SessionContext';
import useItemFavorite from '~/hooks/useItemFavorite';
import usePrice from '~/hooks/usePrice';
import useThumbnailMeta from '~/hooks/useThumbnailMeta';
import FavoriteIcon from '~/public/static/svg/FavoriteIcon';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';
import { RailsFacetItemMeta } from '~/typings/services/rails/facets';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';

// TEMPORARY
const auctionRe = /auction/i;

const GearExpertItemThumbnailAction = dynamic(
  () => import('~/components/GearExperts/Lists/GearExpertItemThumbnailAction'),
);

interface Props {
  // index of iterating through thumbnails in the parent grid component. starts with 0
  position?: number;

  // If true, adds a <link> to <head> indicating a preloaded resource
  preloadImage?: boolean;
  item:
    | RailsThumbnailItem
    | (RailsThumbnailItem & RailsFacetItemMeta)
    | RailsItem;

  onInvalidate?: () => void;

  additionalLowerSection?: React.ReactNode;
  src?: AnalyticsSource;
}

const ItemThumbnail: React.FC<Props> = ({
  additionalLowerSection,
  item,
  onInvalidate,
  position,
  preloadImage,
  src,
}) => {
  const { user } = useSession();
  const { locale } = useRouter();
  const calculatePrice = usePrice({ decimals: false });
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const price = calculatePrice(item.price);

  const meta = useThumbnailMeta(item.id);

  const itemNameCx = 'mb-1 text-sm line-clamp-2';
  const itemNameLink = overlayOpen ? (
    <span className={itemNameCx}>{item.name}</span>
  ) : (
    <ItemDetailsLink
      prefetch={false}
      className={clsx(
        itemNameCx,
        'hover:underline',
        !additionalLowerSection && 'thumbnail-link',
      )}
      item={item}
      title={item.name}
      src={src}
    >
      {item.name}
    </ItemDetailsLink>
  );

  const qualifiesForActionOverlay = !!(
    meta &&
    (meta.actions.find(
      a =>
        a === 'edit' ||
        a === 'take_down' ||
        a === 'remove_gmc' ||
        a === 'unreport',
    ) ||
      user?.username === item.seller.username)
  );

  let shouldShowBeforePrice = false;
  const priceBefore =
    'price_before' in item ? item.price_before : item.list_price;

  if (item.label && auctionRe.test(item.label.text)) {
    shouldShowBeforePrice = false;
  } else {
    if (priceBefore != item.price || (locale && locale !== 'en-US')) {
      shouldShowBeforePrice = true;
    }
  }

  const beforePrice = shouldShowBeforePrice && calculatePrice(priceBefore);

  let priceDropPercent: React.ReactNode = null;
  if (beforePrice && beforePrice.price) {
    const priceDrop = Math.round(
      ((beforePrice.price - item.price) / beforePrice.price) * 100,
    );
    if (priceDrop > 0) {
      priceDropPercent = (
        <span className="font-semibold text-green-500">{priceDrop}%</span>
      );
    }
  }

  let retailPrice: React.ReactNode = null;
  if (item.price_retail > 0) {
    const retail = calculatePrice(item.price_retail);
    retailPrice = (
      <span className="text-slate-green-500 mt-1.5 text-xs">
        Retail price: {retail.formattedPrice}
        {retail.nonUSDCurrency && (
          <SuperScriptedCurrency>{retail.nonUSDCurrency}</SuperScriptedCurrency>
        )}
      </span>
    );
  }

  const { favorite, favorited, favoritersCount } = useItemFavorite(item, {
    meta,
  });

  return (
    <div
      className="thumbnail relative flex w-full flex-col rounded-lg"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {qualifiesForActionOverlay && (
        <ItemThumbnailOverlay
          item={item}
          meta={meta}
          onClose={() => setOverlayOpen(false)}
          onInvalidate={onInvalidate}
          show={overlayOpen}
        />
      )}

      {/* Image Container */}
      <div className="thumbnail-image aspect-w-9 aspect-h-11">
        <ItemThumbnailImage
          item={item}
          position={position}
          className="rounded-lg"
          preload={preloadImage && position != null && position < 3}
          zoomContain={hover && !overlayOpen}
        />

        {/* Container to properly position image container content */}
        <div className="flex flex-col justify-between p-2">
          {/* Label and Favorite row */}
          <div className="relative z-10 flex justify-between">
            {meta?.superlative_label ? (
              <SuperlativeLabelTooltip
                superlativeName={meta.superlative_label.text}
              >
                <div className="superlative-ribbon -ml-2">
                  {meta.superlative_label.text}
                </div>
              </SuperlativeLabelTooltip>
            ) : (
              <div />
            )}

            {/* Favorite button */}
            <button
              className="relative -m-2 flex items-center p-2 text-white"
              onClick={e =>
                item.seller.username === user?.username ? null : favorite(e)
              }
            >
              {favoritersCount > 0 && (
                <div className="mr-2 text-sm">{favoritersCount}</div>
              )}
              <FavoriteIcon fill="currentColor" active={favorited} />
            </button>
          </div>

          {/* Username and action row */}
          <div className="relative z-10 flex h-6 items-center justify-between">
            <UsernameAvatarRow item={item} />

            <div className="ml-auto" />

            {qualifiesForActionOverlay && (
              <button
                className="-m-2 p-2 text-white"
                onClick={() => setOverlayOpen(true)}
              >
                <HorizontalDots />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Lower details container */}
      <div className="flex h-full flex-col pt-2">
        {itemNameLink}

        {/* I think this is where we want the whitespace expansion, in the event that multiple items on the same
            row have different heights. */}
        {/*<div className="flex-1" />*/}

        {/* Price row */}
        <div className="flex items-center space-x-2">
          <span
            className={clsx('text-base font-semibold', {
              // @ts-ignore
              'line-through': item.sold_via_offer,
            })}
          >
            {price.formattedPrice}
            {price.nonUSDCurrency && (
              <SuperScriptedCurrency>
                {price.nonUSDCurrency}
              </SuperScriptedCurrency>
            )}
          </span>

          {beforePrice && (
            <span className="text-slate-green-500 line-through">
              {beforePrice.formattedPrice}
              {beforePrice.nonUSDCurrency && (
                <SuperScriptedCurrency>
                  {beforePrice.nonUSDCurrency}
                </SuperScriptedCurrency>
              )}
            </span>
          )}

          {priceDropPercent}

          {/* Item label */}
          {item.label && (
            <ItemLabelPill
              label={item.label}
              auctionEndedAt={meta?.auction_ended_at}
              variant="text"
              preferIcon
            />
          )}
        </div>

        {retailPrice}

        {meta?.shipping_label?.slug === 'free_shipping' && (
          <div className="mt-1.5">
            <ItemFreeShippingPill variant="text" />
          </div>
        )}

        {additionalLowerSection}
      </div>
    </div>
  );
};

export default ItemThumbnail;
