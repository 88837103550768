'use client';

import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Loader } from 'ui';
import Tooltip from '~/components/Form/Tooltip';
import { fetchButterSuperlativeByName } from '~/lib/buttercms/superlatives/superlatives';

export default function SuperlativeLabelTooltip({
  children,
  superlativeName,
}: {
  children: React.ReactNode;
  superlativeName: string;
}) {
  const [visible, setVisible] = useState(false);

  const { data } = useQuery(
    ['butter-superlatives', superlativeName],
    () => fetchButterSuperlativeByName(superlativeName),
    {
      staleTime: Infinity,
      enabled: visible,
    },
  );

  const superlativeDescription = data?.[0]?.description;

  return (
    <Tooltip
      tooltip={
        <div>
          {superlativeDescription ? (
            <>{superlativeDescription}</>
          ) : (
            <>
              <Loader margin={false} size="small" />
            </>
          )}
        </div>
      }
      direction="top"
      onVisibleChange={setVisible}
    >
      {children}
    </Tooltip>
  );
}
