import ButterClass from 'buttercms';
import { getEnv } from 'common-nextjs';
import type { Butter, ButterStatic } from '~/typings/services/buttercms';

function getButterStatic() {
  const key = getEnv('BUTTER_CMS_API_KEY');
  if (!key) {
    console.warn('ButterCMS is being loaded but no key is found');
    return null as any;
  }

  return ButterClass(key) as any;
}

export const butterApi: ButterStatic = getButterStatic();

export const getButterPosts = async () => {
  try {
    const response = await butterApi.post.list();
    return response.data?.data || null;
  } catch (e) {
    return null;
  }
};

export const getButterTag = async (tag: string) => {
  try {
    const tagData = await butterApi.tag.retrieve(tag);
    return tagData.data?.data || null;
  } catch (e) {
    return null;
  }
};

export const getButterCategory = async (category: string) => {
  try {
    const categoryData = await butterApi.category.retrieve(category);
    return categoryData.data?.data || null;
  } catch (e) {
    return null;
  }
};

export const searchButterPosts = async (query: string, page: number) => {
  try {
    const response = await butterApi.post.search(query, {
      page,
      page_size: 12,
    });
    return response.data || null;
  } catch (e) {
    return null;
  }
};

export async function getButterPage<M extends object>(
  slug: string,
  pageType = '*',
  params?: Butter.PageRetrieveParams,
) {
  try {
    const response = await butterApi.page.retrieve<M>(pageType, slug, params);
    return response.data?.data || null;
  } catch (e) {
    return null;
  }
}

export async function getButterCollection<M extends object>(slug: keyof M) {
  try {
    // @ts-ignore
    const response = await butterApi.content.retrieve<M>([slug], {
      page_size: 200,
    });
    return response.data?.data || null;
  } catch (e) {
    return null;
  }
}

export async function getButterCollectionByField<
  T extends object,
  K extends keyof T = keyof T,
>(
  slug: K,
  fields: Partial<{
    [P in keyof T[K]]: T[K][P];
  }>,
) {
  try {
    const renamedFields = Object.entries(fields).reduce(
      (acc, [key, value]) => {
        acc[`fields.${key}`] = value;
        return acc;
      },
      {} as Record<string, any>,
    );

    // @ts-ignore
    const response = await butterApi.content.retrieve<T>([slug], {
      ...renamedFields,
      page_size: 200,
    });
    return response.data?.data || null;
  } catch (e) {
    return null;
  }
}

export async function getButterAuthor(slug: string) {
  try {
    const response = await butterApi.author.retrieve(slug, {
      include: 'recent_posts',
    });
    return response.data?.data || null;
  } catch (e) {
    return null;
  }
}

export const getButterPostBySlug = async (slug: string) => {
  try {
    const response = await butterApi.post.retrieve(slug);
    return response.data?.data || null;
  } catch (e) {
    return null;
  }
};

export const getButterPostsByTag = async (tag: string) => {
  try {
    const response = await butterApi.post.list({ tag_slug: tag });
    return response.data?.data || null;
  } catch (e) {
    return null;
  }
};

export const getButterPostsByCategory = async (
  category: string,
  page?: number,
) => {
  try {
    const response = await butterApi.post.list({
      category_slug: category,
      page_size: 12,
      page,
    });
    return response.data || null;
  } catch (e) {
    return null;
  }
};

export const getButterPostsByCategoryAndTag = async (
  category: string,
  tag: string,
  page?: number,
) => {
  try {
    const response = await butterApi.post.list({
      page,
      category_slug: category,
      tag_slug: tag,
      page_size: 12,
    });
    return response.data || null;
  } catch (e) {
    return null;
  }
};
