import { getButterCollectionByField } from '~/lib/buttercms/api';

type SuperlativeTooltip = {
  superlative_tooltips: {
    superlative_label: string;
    description: string;
    url?: string;
  };
};

export async function fetchButterSuperlativeByName(name: string) {
  const response = await getButterCollectionByField<SuperlativeTooltip>(
    'superlative_tooltips',
    {
      superlative_label: name,
    },
  );
  return response?.['superlative_tooltips'] || null;
}
